import React from 'react'
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'
import BotButton from '../components/landBot/BotButton'
import ThreeFeatures from '../components/carousels/ThreeFeatures'
import Testimonials from '../components/layout/Testimonials'
import FeaturedSection from '../components/layout/FeaturedSection'
import ParallaxContainer from '../components/parallax/ParallaxContainer'
import FadingTitle from '../components/general/FadingTitle'
import ServicesSection from '../components/general/ServicesSection'
import LocationsSection from '../components/general/LocationsSection'
import FloatingContainer from '../components/floats/FloatingContainer'
import FloatingShape from '../components/floats/FloatingShape'
import TestimonialCardOne from '../components/general/TestimonialCardOne'
import TestimonialCardTwo from '../components/general/TestimonialCardTwo'
import Button from '../../src/components/general/Button'
import Star from '../images/ui/star.svg'
import StairCase from '../images/offices/H1_Kontor.jpg'
import MiniCard from '../components/general/MiniCard'
import RICS from '../images/other/RICS.png'
import SkyOffice from '../images/offices/H4_Kontor.jpg'
import OpenSpace from '../images/offices/H5_Kontor.jpg'
import Hall from '../images/offices/H6_Kontor.jpg'

const OfficeHours = ({ location }) => (
    <Layout gutters={false} container={false} activePage={'/'} location={location}>
        <Header title="Home - Find your perfect office space" />
        <FadingTitle
            title={<><span className="border-b-2 border-secondary font-bold">Office Hours</span> - Book a free consultation with one of our workspace experts</>}
            boxText="What size office should we get? How much should it cost? What’s hybrid? Chat to the office experts for honest, free advice."
            cta={(
                <Button
                    type="secondary"
                    className="py-1 uppercase text-center font-bold shadow-charlie sm:text-2xl w-68 sm:w-96"
                    linkTo="https://meetings.hubspot.com/polly2/office-hours"
                >
                    Book your call now
                </Button>
            )}
            textUnderCta="(For free! No credit card required)"
            path={location.search || ''}
            loadAnimation={false}
        />
        <FeaturedSection className="max-w-6xl mx-auto md:px-6 my-24 mt-30vh desktopShort:mt-20vh desktopTall:mt-10vh" />
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="50"
            className="mb-24 max-w-6xl mx-auto px-6 relative md:-mt-16"
        >
            <ThreeFeatures
                features={[
                    {
                        title: 'Flexible Spaces',
                        subTitle: <span>Make hybrid work, work. The perfect office space for your team is out there, whether it’s managed, serviced, leased, part time or full time, we can help!</span>
                    },
                    {
                        title: 'Dedicated Experts',
                        subTitle: <span>Fast replies, friendly faces, always on your side. Our workplace experts are armed with years of experience, up to the minute market availability and tip top tech to make your whole experience a breeze.</span>
                    },
                    {
                        title: <span className="flex">Rated 5<img className="mb-2" src={Star} alt="Rating star" /></span>,
                        subTitle: <span>Honestly! Take a look at our 180+ <a className="hover:text-secondary underline" href="https://www.google.co.uk/search?ei=a0jNXZq9F8TKgQa23qbQDw&q=kontor&oq=kontor&gs_l=psy-ab.3..0l10.5390.7200..7555...2.4..0.153.663.7j1#lrd=0x48761cb69c43afe7:0x34d62d870292bdf9,1,,," target="_blank" rel="noopener noreferrer" aria-label="GoogleReviews">Google reviews</a> to see how we’ve helped scaleups and unicorns around the world find their perfect office space.</span>
                    }
                ]}
            />
        </div>
        <div className="mx-auto text-center mt-24 relative" style={{ maxHeight: '500px' }}>
            <FloatingContainer
                className="lg:w-1/2 w-full mx-auto lg:left-1/4 -top-32 lg:top-0 "
                floatSpeed="custom"
                speed={5000}
                floatType="spin"
                height="200px"
            >
                <FloatingShape height={160} width={160} shape="halfMoon" />
            </FloatingContainer>
            <ParallaxContainer weighting={1.3} reverse={true} early={100} className="justify-center">
                <TestimonialCardOne />
                <img
                    className="inline-block md:mt-40 transition-quick self-end max-w-90p md:max-w-sm"
                    src={StairCase}
                    alt="Kontor Office Chair"
                />
            </ParallaxContainer>
            <FloatingContainer
                className="lg:w-1/2 w-full mx-auto lg:left-1/2 -top-32 lg:top-0 "
                floatSpeed="custom"
                speed={5000}
                floatType="wobbleDrift"
                height="400px"
            >
                <FloatingShape height={180} width={180} shape="polygon" />
            </FloatingContainer>
        </div>
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="max-w-6xl mx-auto px-6"
            style={{ marginTop: '24rem' }}
        >
            <h2 className="text-center">That <span className="border-b-2 border-secondary font-bold"> 'how do we get the team back together?'</span> moment</h2>
            <div className="flex mt-20 flex-col lg:flex-row" style={{ minHeight: 600 }}>
                <div className="md:w-3/3 lg:w-1/3 leading-loose relative pr-0 lg:pr-16">
                    <MiniCard type="primary" className="relative my-2 z-10 text-left" arrow={false} >
                        AWESOME TEAMS DESERVE AN OFFICE THAT HELPS THEM DO AWESOME THINGS
                    </MiniCard>
                    <p className="leading-relaxed mt-4">
                        Working from home has its upsides but some things are really missing.
                        Like *feeling* what’s going on. Serendipity. Relationships.
                        For innovators who rely on being in the same room to spitball ideas at speed, that’s an issue.
                    </p>
                    <p className="leading-relaxed mt-4">
                        So the search begins for a flexible space that ticks all the boxes.
                        Even if you’ve loads on your plate already or there’s uncertainty around your hybrid work plans,
                        finding an office doesn’t have to mean a to-do list meltdown.
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="md:w-3/3 lg:w-1/3 mt-12 lg:mt-32 leading-loose px-0 lg:px-6"
                >
                    <MiniCard type="primary" arrow={false} className=" my-2 text-left l tracking-wide">
                        A PATH THROUGH THE UNKNOWN-UNKNOWNS
                    </MiniCard>
                    <p className="leading-relaxed mt-4">
                        Life is more complicated than ever,
                        and you’re probably wondering what the Office Of The Future looks like.
                    </p>
                    <p className="leading-relaxed mt-4">
                        How much space do you need? How long for? Are the start/end dates flexible?
                        When to start looking? How to manage the process? Are there deals to be had if you sign up now?
                        Must you pay for days you don’t use? Don’t worry, we’ll get you all the answers.
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="md:3/3 lg:w-1/3 leading-loose mt-12 lg:mt-64 mb-8 pl-0 lg:pl-16"
                >
                    <FloatingContainer
                        className="w-full mx-auto -top-32 lg:top-0 "
                        floatSpeed="custom"
                        speed={5000}
                        floatType="spin"
                        height="200px"
                    >
                        <FloatingShape height={120} width={120} shape="halfMoon" />
                    </FloatingContainer>
                    <ParallaxContainer weighting={1.3} early={100} className="justify-center" reverse />
                    <MiniCard type="primary" arrow={false} className="my-2 text-left">
                        YOU DON’T HAVE TO GO IT ALONE
                    </MiniCard>
                    <p className="leading-relaxed mt-4">
                        No two office searches are ever the same. Understanding the vibe of your company matters,
                        at least as much as the ability to adapt.
                    </p>
                    <p className="leading-relaxed mt-4">
                        We know this first hand. We’ve moved around a fair bit over the years.
                        Scaling up, down, and back up. All the while figuring out how to make an office first strategy,
                        combined with WFH and remote, all add up. Rest assured, it can be done!
                    </p>
                    <p className="leading-relaxed mt-4">
                        Looking for the expertise you can trust, from people who are on your side?
                        Offices are personal. So are we.
                    </p>
                </div>
            </div>
        </div >
        <div className="mt-8 md:mt-16 text-center">
            <BotButton id="h-lb-toseeyournextoffice" type="secondary" className="mx-4">
                To see your next office, answer some quick Q's
            </BotButton>
        </div>
        <FloatingContainer
            className="w-1/2 left-1/4 top-48"
            floatSpeed="custom"
            speed={10000}
            floatType="largeWobbleDrift"
            height="600px"
        >
            <FloatingShape height={110} width={110} shape="circle" />
        </FloatingContainer>
        <LocationsSection />
        <div
            style={{ maxHeight: '500px' }}
            data-aos="fade-up"
            data-aos-duration="1000"
            className="mx-auto text-center m-64 relative"
        >
            <ParallaxContainer weighting={2} className="justify-center" reverse>
                <img
                    className="self-start ml-40 md:ml-0 h-full transition-quick max-w-sm"
                    src={Hall}
                    alt="Office Hall"
                />
                <img
                    className="-ml-8 mt-48 transition-quick h-full max-w-xl z-10"
                    src={OpenSpace}
                    alt="Office Open Space"
                />
                <TestimonialCardTwo />
            </ParallaxContainer>
        </div>
        <ServicesSection />
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100" className="mt-36 px-0 md:px-8 mb-0 lg:mb-8">
            <h2 className="text-center mt-8 px-8 mb-8"> We couldn't have said it better...</h2>
            <p className="text-md text-center mt-4 px-8 "><span className="border-b-2 border-secondary"> We love our clients! </span> Thankfully, they love us too.</p>
            <Testimonials className="mt-16" />
        </div>
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="flex flex-col md:flex-row max-w-5xl mx-auto my-24 px-0"
        >
            <div className="w-full lg:w-1/2 relative self-center">
                <h2 className="text-3xl px-0 mb-12 md:hidden text-center mb-24">Hooray, we're <br />regulated!</h2>
                <ParallaxContainer>
                    <img
                        className="max-w-sm transition-quick w-full h-full"
                        src={SkyOffice}
                        alt="High rise Office"
                    />
                    <img
                        className="absolute md:top-48 -top-4 transition-quick right-0"
                        src={RICS}
                        alt="Regulation Authority Logo"
                    />
                </ParallaxContainer>
                <FloatingContainer
                    className="w-full lg:-top-32 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="drift"
                    height="600px"
                    rotate={false}
                >
                    <FloatingShape height={100} width={100} shape="circle" />
                </FloatingContainer>
            </div>
            <div className="w-full lg:w-1/2 self-center leading-loose px-6 mt-12 md:mt-4 text-lg">
                <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-1/2 -top-16 lg:top-0 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="spin"
                    height="200px"
                >
                    <FloatingShape height={140} width={140} shape="halfMoon" />
                </FloatingContainer>
                <h2 className="text-4xl px-0 mb-12 hidden lg:block">Hooray, we're regulated!</h2>
                <p>
                    You want a good job done well. Kontor is fully regulated by
                    the RICS (Royal Institution of Chartered Surveyors). Fully insured.
                    Fully stocked with industry qualifications. Fully… you get the gist.
                </p>
                <p>Seriously though, you should work with us. It’ll be fun.</p>
            </div>
        </div>
        <div className="mt-24 md:mt-12 text-center mb-64 md:mb-24 z-1 relative">
            <BotButton id="h-lb-getgoing" type="secondary">Get going in under 60 seconds</BotButton>
        </div>
    </Layout >
)

export default OfficeHours
